import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FaCircleArrowRight } from "react-icons/fa6";
import axios from 'axios';
import headLogo from "../assets/logo.png";

function Form() {

    const [limitCount, setLimitCount]                              = useState(0);
    const [textInformation, setTextInformation]                    = useState("");
    const [selectedFiles, setSelectedFiles]                        = useState([]);

    const [processing, setProcessing]                              = useState(false);
    const fileInputRef                                             = useRef(null);

    const [emailAdress, setEmailAddress]                           = useState("");
    const [nameContent, setNameContent]                            = useState("");

    const handleButtonClick = () => {
      fileInputRef.current.click(); // Programmatically trigger the file input
    };
  
    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length + limitCount > 6) {
          toast.error("Up to six images maximum"); // Replace with a toast library if you use one
          event.target.value = ""; // Reset the file input
          setLimitCount(0);
        } else {
            setLimitCount(limitCount + files.length); // Update the count
            setSelectedFiles([...selectedFiles, ...files]);
        }
    };
    
    const handleSubmission = async () => {
        try{
            setProcessing(true);
              if(textInformation.length > 3){
                const valid = isValidEmail(emailAdress);
                console.log(valid);

                    if(valid){
                       

                        const formData = new FormData();
                            formData.append("description", textInformation);
                            formData.append("emailAddress", emailAdress);
                            formData.append("nameText", nameContent);

                            selectedFiles.forEach((file, index) => {
                                formData.append(`image${index + 1}`, file);
                            });
                
                        const response = await axios.post("https://review.goawol.co.za/assess/server.php", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });
                            /*
                            console.log("||||||||||||||||||||||||||||||||||||");
                            console.log(response);
                            console.log("||||||||||||||||||||||||||||||||||||");
                            */
                            toast.success(response.data.message);

                            setSelectedFiles([]);
                            setTextInformation("");
                            setLimitCount(0);
                            setEmailAddress("")
                            setNameContent("");
                            fileInputRef.current.value = null; // Clear the file input
                    }else {
                        toast.error("Email is not valid.")
                    }                      
                    
              }else {
                toast.error("Please add your comment before submission.");
                return;
              }
              setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        <div className="main-standard">
            <div className="content--area">
                <div className="target-head">
                    <div className="logo-base">
                        <img src={headLogo} className="logo-item" />
                    </div>
                    <div className="titling">
                        Hi there, <br/>
                        Share your experience with the product you purchased on our site!
                    </div>
                </div>
                <div className="description-item">
                    Please take a moment to tell us about your experience. Don't forget to upload a photo of the product!<br/>
                    If you encountered any issues or have concerns, please contact our customer support team directly at [011 000 0000]. We're here to help!<br/>
                    Your review will help us improve our products and services, and we appreciate your feedback!<br/>
                    Thank you for taking the time to share your thoughts!
                </div>
                <div className="text-area-outer-lay">
                   <div className="email-bot">
                        <input type="text" value={emailAdress} onChange={(eml) => setEmailAddress(eml.target.value)} className="txt-settle email-text" placeholder="Email Address..." />
                        <input type="text" value={nameContent} onChange={(eml) => setNameContent(eml.target.value)} className="txt-settle copy-text" placeholder="First Name..." />
                    </div>
                    <p>Tell us about your experience?</p>
                    
                    <textarea placeholder="Type here..." onChange={(content) => setTextInformation(content.target.value)} value={textInformation}></textarea>
                    <div className="flexme attack-space">
                         <div className="halfflex">
                            <input 
                                type="file" 
                                className="display-hide" accept="image/*" 
                                multiple
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                />
                            <button className="plein-button" onClick={handleButtonClick}>Add up to 6 Image </button>
                            <p className="show-count">{limitCount} images</p>
                         </div>
                         <div className="halfflex display-mac">
                            <button className="btn-faArrow" onClick={() => handleSubmission()}><FaCircleArrowRight /></button>
                         </div>
                    </div>
                </div>
            </div>

            {
                processing && (
                    <div className="img-source">
                        <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
            }
        </div>
    )
}
export default Form