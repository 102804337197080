import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

//Get User
const user = JSON.parse(localStorage.getItem(CONSTANTS.SESSION_COOKIE));

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

//Login
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
    try{

        const response = await axios.post(CONSTANTS.API_URL + "auth/login", user);
        localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(response.data));

        return response;

    }catch(error){
        const message = error.response.data
        return thunkAPI.rejectWithValue(message)
    }
})
////////////////////////////////////////////////////////
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = false 
                state.user = action.payload.data 
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true 
                state.message = action.payload
                state.user = null
            })
    }
})

export const { updateUser } = authSlice.actions
export default authSlice.reducer