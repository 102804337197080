
export const API_URL = "https://mevent-phone-4f690ce5228b.herokuapp.com/api/";
export const SESSION_COOKIE = "userMeventMobilBlackCutterxLive";

/*
export const API_URL = "http://localhost:55021/api/";
export const SESSION_COOKIE = "userMeventMobilBlackCutterxLocal";
*/
export const SMALL_PROCESSING = "http://cjmarketing.co/image_directory/orange_circles.gif";
export const BLUE_PROCESSING = "http://cjmarketing.co/image_directory/blue-load.gif";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";
export const VERSION = "1.1.0";
export const WP_URL = "https://new.cjmarketing.co/wp-json/wp/v2/";