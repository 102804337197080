import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Form from "./Pages/Form";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
    <Router>
      <div className="main-container">
        <Routes>
           <Route path="/" element={<Form />}/>  
        </Routes>
      </div>
    </Router>
    <ToastContainer />
    </>
  );
}

export default App;
